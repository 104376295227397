import React, { useEffect, useState } from "react";
import "./inquire.scss";
import { toast, ToastContainer } from "react-toastify";
import { API } from "../../App";
import { useParams } from "react-router-dom";
import { SalesList } from "../../data/assets/sales-list";

const Inquire = () => {
  const [mounted, setMounted] = useState(false);

  let { aircraft_name } = useParams();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [aircraftName, setAircraftName] = useState(aircraft_name);
  const [sendBtnText, setSendBtnText] = useState("Send Message");
  const [sendBtnClass, setSendBtnClass] = useState("contact-submit");

  function sendMessage() {
    // document.getElementById("btnPlaceOrder").disabled = true;
    document.getElementById("sendBtn")?.setAttribute("disabled", "disabled");
    setSendBtnClass("contact-submit-disabled");
    setSendBtnText("Sending...");
    const body = {
      name: name,
      email: email,
      phone: phone,
      aircraftName: aircraftName,
    };
    API.post("/api/email/sales/inquire", body)
      .then((response) => {
        // response.data.forEach((element: any) => {
        //   console.log(element.msg);
        // });
        if ((response.status = 200)) {
          toast.success("Message send successfully");
          setName("");
          setEmail("");
          setPhone("");
        } else {
          toast.error("Somethin went wrong");
        }
        document.getElementById("sendBtn")?.removeAttribute("disabled");
        setSendBtnClass("contact-submit");
        setSendBtnText("Send Message");
      })
      .catch((error) => {
        const errArray = [];
        if (error.response) {
          error.response.data.forEach((element: any) => {
            console.log(element.msg);
            // errArray.push(element.msg);
            toast.error(element.msg);
          });
        } else {
          toast.error(error.message);
          // alert(error.message);
        }
        document.getElementById("sendBtn")?.removeAttribute("disabled");
        setSendBtnClass("contact-submit");
        setSendBtnText("Send Message");

        // console.log(error.response);
      });
  }

  if (!mounted) {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <div className="inquire-container">
      <div className="inquire-wrapper">
        <div className="drop-heading-wrapper">
          <p className="drop-heading">Send an Inquiry</p>
        </div>
        <div className="contact-form">
          <input
            className="contact-input"
            onChange={(e) => setName(e.target.value)}
            type="text"
            id="name"
            name="name"
            placeholder="Name"
            value={name}
          />
          <input
            className="contact-input"
            onChange={(e) => setPhone(e.target.value)}
            type="text"
            id="phone"
            name="phone"
            placeholder="Mobile number"
            value={phone}
          />
          <input
            className="contact-input"
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            id="email"
            name="email"
            placeholder="E-mail"
            value={email}
          />
          <p className="aircraft-name">{aircraftName}</p>
          <button id="sendBtn" onClick={sendMessage} className={sendBtnClass}>
            {sendBtnText}
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Inquire;
