import React, { useEffect, useState } from "react";
import "./aviation.scss";
import PilotsImg from "../../data/images/aviation/pilots.png";
import RatingImg from "../../data/images/aviation/type-rating.webp";
import PmImg from "../../data/images/aviation/pm.webp";
import LeaseImg from "../../data/images/aviation/leasing.webp";
import TeamImg from "../../data/images/aviation/team.webp";
import { Link } from "react-router-dom";

const Aviation = () => {
  const [mounted, setMounted] = useState(false);
  if (!mounted) {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <div className="aviation-container container">
      <p className="aviation-heading">Aviation</p>
      <div className="aviation-description-wrap">
        <p className="aviation-description">
          At Akhurath, we blend our in-house expertise with worldwide
          collaborations to serve a diverse range of aviation profiles. From
          feasibility studies for aviation projects to comprehensive training
          across various sectors, setting up training establishments, and
          spearheading research and development initiatives, we cover it all.
        </p>
        <p className="aviation-description">
          Our services extend to crew and personnel resourcing, facilitating
          aircraft leasing ,projects from i Our services extend to crew and
          personnel resourcing, facilitating aircraft leasing ,projects from
          inception to takeover and merger.nception to takeover and merger.
        </p>
        <p className="aviation-description">
          {" "}
          With a commitment to excellence and a global network of partners,
          Akhurath is your trusted partner for success in the aviation industry.
        </p>
      </div>
      <div className="aviation-item-container">
        <div className="aviation-item">
          <img src={LeaseImg} alt="" className="avi-item-img" />
          <div className="avi-item-content">
            <p className="avi-item-head">AIRCRAFT AND EQUIPMENT LEASING</p>
            <p className="avi-item-para">
              Your premier partner in aircraft leasing and procurement
              solutions. Through our extensive network of global counterparts,
              Akhurath specializes in facilitating the lease and purchase of
              aircraft and aviation-related equipment for our clients. Whether
              you're an airline, charter company, or aviation enthusiast, our
              platform offers seamless access to a diverse range of aircraft and
              equipment options tailored to your specific needs and preferences.
              Contact us today to explore how Akhurath can assist you in
              securing the ideal aircraft and aviation-related equipment for
              your operations.
            </p>
            <Link to={"/aviation/sales_lease/"}>
              <button
                id="inquire-btn"
                onClick={() => {
                  return "";
                }}
                className="aviation-item-btn"
              >
                Sales & Leasing
              </button>
            </Link>
          </div>
        </div>
        {/*  */}
        <div className="aviation-item">
          <img src={PilotsImg} alt="" className="avi-item-img" />
          <div className="avi-item-content">
            <p className="avi-item-head">PILOTS--ENGINEERS--CABIN CREW</p>
            <p className="avi-item-para">
              Collaborating with esteemed training centers across Georgia, USA,
              UK, India, Singapore, Vietnam, UAE, Australia, New Zealand,South
              Africa and various European countries, we provide personalized
              consultation and comprehensive training guidance for aspiring
              aviation professionals. We cater to students aspiring to become
              pilots, aircraft engineers, AMEs, cabin crew, and more. From
              induction to placements, our meticulously tailored programs ensure
              students are equipped with sought-after skills and qualifications,
              backed by unwavering support.
            </p>
          </div>
        </div>
        <div className="aviation-item">
          <img src={RatingImg} alt="" className="avi-item-img" />
          <div className="avi-item-content">
            <p className="avi-item-head">AIRCRAFT TYPE RATING</p>
            <p className="avi-item-para">
              Welcome to Akhurath, your gateway to professional aviation
              training worldwide. In collaboration with leading training centers
              across the globe, Akhurath specializes in facilitating candidates'
              Type Rrating courses for a wide range of aircraft, including
              Airbus, Boeing, Embraer, and ATR models. Our platform streamlines
              the process, ensuring pilots secure the essential training they
              need to excel in their careers. With Akurath, embark on your
              journey to mastery with confidence and precision. Explore our
              offerings today
            </p>
          </div>
        </div>
        <div className="aviation-item">
          <img src={PmImg} alt="" className="avi-item-img" />
          <div className="avi-item-content">
            <p className="avi-item-head">AVIATION PROJECT MANAGEMENT</p>
            <p className="avi-item-para">
              Our dynamic in-house team collaborates closely with market experts
              to tackle a wide spectrum of aviation projects. From conducting
              comprehensive feasibility studies to orchestrating mergers,
              takeovers, and revivals of existing airlines, to pioneering the
              establishment of new airliners and aviation training centers on a
              global scale, we specialize in navigating the complexities of the
              aviation industry. Whether you're looking to revitalize an
              existing airline or embark on an ambitious venture, trust us to
              deliver tailored solutions that soar.
            </p>
          </div>
        </div>

        <div className="aviation-item">
          <img src={TeamImg} alt="" className="avi-item-img" />
          <div className="avi-item-content">
            <p className="avi-item-head">Our Team</p>
            <p className="avi-item-para">
              Our team comprises globally renowned experts from diverse streams
              of aviation, each bringing a wealth of experience and knowledge to
              the table. From seasoned pilots and aircraft engineers to aviation
              economists and regulatory specialists, our team is dedicated to
              delivering unparalleled service and innovative solutions to our
              clients worldwide. Discover the advantage of working with Akhurath
              and tap into the collective wisdom of our esteemed team for all
              your aviation needs.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aviation;
