import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBCollapse,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
} from "mdb-react-ui-kit";
import React, { useState } from "react";
import Brand from "../brand/brand";
import { Link } from "react-router-dom";
import "./navbar.scss";

const Navbar = () => {
  const [openBasic, setOpenBasic] = useState(false);
  return (
    <div className="nav-container">
      <MDBNavbar fixed="top" expand="lg" bgColor="light" light>
        <MDBContainer fluid>
          <MDBNavbarBrand onClick={() => setOpenBasic(false)}>
            <Link to="/">
              <Brand />
            </Link>
          </MDBNavbarBrand>

          <MDBNavbarToggler
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setOpenBasic(!openBasic)}
          >
            <MDBIcon icon="bars" fas />
          </MDBNavbarToggler>

          <MDBCollapse
            navbar
            open={openBasic}
            // onClick={() => setOpenBasic(false)}
          >
            <MDBNavbarNav className="mr-auto mb-2 mb-lg-0">
              <MDBNavbarItem>
                <Link to="/">
                  <MDBNavbarLink
                    active
                    aria-current="page"
                    href="#"
                    className="navbar-link"
                    onClick={() => setOpenBasic(false)}
                  >
                    Home
                  </MDBNavbarLink>
                </Link>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <Link to="/aboutus">
                  <MDBNavbarLink
                    active
                    aria-current="page"
                    href="#"
                    className="navbar-link"
                    onClick={() => setOpenBasic(false)}
                  >
                    About Us
                  </MDBNavbarLink>
                </Link>
              </MDBNavbarItem>
              {/* <MDBNavbarItem>
                <Link to="/aviation">
                  <MDBNavbarLink
                    active
                    aria-current="page"
                    href="#"
                    className="navbar-link"
                    onClick={() => setOpenBasic(false)}
                  >
                    Aviation
                  </MDBNavbarLink>
                </Link>
              </MDBNavbarItem> */}
              <MDBNavbarItem>
                <MDBDropdown>
                  <MDBDropdownToggle tag="a" className="nav-link" role="button">
                    Aviation
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <Link to="/aviation">
                      <MDBNavbarLink
                        active
                        aria-current="page"
                        href="#"
                        className="navbar-link"
                        onClick={() => setOpenBasic(false)}
                      >
                        Aviation
                      </MDBNavbarLink>
                    </Link>
                    <Link to="/aviation/sales_lease/">
                      <MDBNavbarLink
                        active
                        aria-current="page"
                        href="#"
                        className="navbar-link"
                        onClick={() => setOpenBasic(false)}
                      >
                        Sales & Leasing
                      </MDBNavbarLink>
                    </Link>

                    {/* <MDBDropdown>
                      <MDBDropdownToggle
                        tag="b"
                        className="nav-link"
                        role="button"
                      >
                        Dropdown
                      </MDBDropdownToggle>
                      <MDBDropdownMenu>
                        <MDBDropdownItem link>Action</MDBDropdownItem>
                        <MDBDropdownItem link>Another action</MDBDropdownItem>
                        <MDBDropdownItem link></MDBDropdownItem>
                      </MDBDropdownMenu>
                    </MDBDropdown> */}
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <Link to="/investment">
                  <MDBNavbarLink
                    active
                    aria-current="page"
                    href="#"
                    className="navbar-link"
                    onClick={() => setOpenBasic(false)}
                  >
                    Investment
                  </MDBNavbarLink>
                </Link>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <Link to="/healthcare">
                  <MDBNavbarLink
                    active
                    aria-current="page"
                    href="#"
                    className="navbar-link"
                    onClick={() => setOpenBasic(false)}
                  >
                    Healthcare
                  </MDBNavbarLink>
                </Link>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <Link to="/contact-us">
                  <MDBNavbarLink
                    active
                    aria-current="page"
                    href="#"
                    className="navbar-link"
                    onClick={() => setOpenBasic(false)}
                  >
                    Contact Us
                  </MDBNavbarLink>
                </Link>
              </MDBNavbarItem>
            </MDBNavbarNav>

            {/* <form className="d-flex input-group w-auto">
              <input
                type="search"
                className="form-control"
                placeholder="Type query"
                aria-label="Search"
              />
              <MDBBtn color="primary">Search</MDBBtn>
            </form> */}
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    </div>
  );
};

export default Navbar;
