import React from "react";
import "./footer.scss";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="container footer-wrapper">
        <p className="footer-text">
          Copyright © 2024 AKHURATH ZENITH SOLUTIONS - All Rights Reserved.
        </p>
        <p className="footer-text">CREATED BY PUTTU ICE CREAM</p>
      </div>
    </div>
  );
};

export default Footer;
