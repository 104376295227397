import {
  MDBCarousel,
  MDBCarouselItem,
  MDBCarouselCaption,
} from "mdb-react-ui-kit";
import "./simulator.scss";
import React from "react";

const Simulator = () => {
  return (
    <div className="simulator-container">
      <p className="simulator-head">Simulator Training</p>
      <div className="simulator-wrapper container">
        <MDBCarousel showIndicators showControls fade className="sim-carousel">
          <MDBCarouselItem itemId={1}>
            <div className="sim-carousel-img">
              <img
                src={require("../../data/images/simulator/a320-baa-sim.jpg")}
                className="d-block w-100 "
                alt="..."
              />
            </div>
            <MDBCarouselCaption>
              <h5>Airbus A320</h5>
              <p>Full Motion Simulator Training</p>
            </MDBCarouselCaption>
          </MDBCarouselItem>

          <MDBCarouselItem itemId={2}>
            <div className="sim-carousel-img">
              <img
                src={require("../../data/images/simulator/b737-baa-sim.jpg")}
                className="d-block w-100 "
                alt="..."
              />
            </div>
            <MDBCarouselCaption>
              <h5>Boeing 737</h5>
              <p>Full Motion Simulator Training</p>
            </MDBCarouselCaption>
          </MDBCarouselItem>
        </MDBCarousel>
        <p className="sim-para">
          Akhurath Zenith Solutions LLC – Comprehensive A320/B737 Full Motion
          Simulator Training
        </p>
        <p className="sim-bold">Welcome to Akhurath Zenith Solutions LLC</p>
        <p className="sim-para">
          At Akhurath Zenith Solutions LLC, we are dedicated to providing
          world-class aviation training in partnership with industry-leading
          facilities. Our comprehensive training programs are tailored for both
          aspiring and experienced pilots, ensuring that you receive the best
          preparation for your aviation career. We are proud to offer Initial
          Type Rating courses in A320 and B737 aircraft using state-of-the-art
          full motion simulators, approved by the Indian Directorate General of
          Civil Aviation (DGCA).
        </p>
        <p className="sim-para">
          In addition to our exceptional training, we offer competitive industry
          rates, along with complimentary accommodation, pickup, and drop-off
          services, ensuring a seamless and comfortable experience during your
          training period.
        </p>
        {/*  */}
        <p className="sim-subhead">Training Programs</p>
        <p className="sim-program-head">1. Initial Type Rating on A320/B737</p>
        <p className="sim-para">
          Our Initial Type Rating course is designed to equip pilots with the
          skills and knowledge necessary to operate either the Airbus A320 or
          Boeing 737 aircraft safely and efficiently. This program is ideal for
          pilots transitioning to a new aircraft type or seeking to expand their
          qualifications.
        </p>
        <ul className="sim-program-ul">
          <li className="sim-program-li">
            Course Duration: Typically 4-6 weeks, depending on the student’s
            proficiency and training schedule.
          </li>
          <li className="sim-program-li">
            Eligibility: Hold a Commercial Pilot License (CPL) or an Airline
            Transport Pilot License (ATPL) with valid medical certification.
          </li>
          <li className="sim-program-li">Course Structure:</li>
          <li className="sim-program-li">
            Ground School: Comprehensive theoretical knowledge covering aircraft
            systems, performance, standard operating procedures, and emergency
            procedures.
          </li>
          <li className="sim-program-li">Simulator Sessions:</li>
          <li className="sim-program-li">
            Full Motion Simulator Training: Experience the A320 or B737 aircraft
            in a controlled environment using cutting-edge full motion
            simulators.
          </li>
          <li className="sim-program-li">
            Scenario-Based Training: Focus on real-life scenarios including
            normal and emergency procedures, system malfunctions, and
            challenging weather conditions.
          </li>
          <li className="sim-program-li">
            Check Ride: Final evaluation conducted by a DGCA-approved examiner
            to assess your proficiency and readiness for aircraft operations.
          </li>
        </ul>
        {/*  */}
        <p className="sim-program-head">
          2. Recurrency and Revalidation Training
        </p>
        <p className="sim-para">
          Stay current with your type rating by enrolling in our Recurrency and
          Revalidation programs. These courses are designed to ensure that you
          maintain your competency and stay updated with the latest operational
          procedures and regulatory changes.
        </p>
        <ul className="sim-program-ul">
          <li className="sim-program-li">
            Course Duration: 2-3 days, depending on the specific requirements.
          </li>
          <li className="sim-program-li">
            Eligibility: Pilots holding a valid A320/B737 type rating.
          </li>
          <li className="sim-program-li">Course Structure:</li>
          <li className="sim-program-li">
            Refresher Training: Review of aircraft systems, operating
            procedures, and updates.
          </li>
          <li className="sim-program-li">
            Simulator Check: A full motion simulator session focusing on
            handling both routine operations and emergency situations.
          </li>
          <li className="sim-program-li">
            Revalidation Assessment: Completion of a check ride to renew your
            type rating, in line with DGCA requirements.
          </li>
          <li className="sim-program-li"></li>
        </ul>
        {/*  */}
        {/*  */}
        <p className="sim-program-head">
          3. Advanced CRM and LOFT (Line-Oriented Flight Training)
        </p>
        <p className="sim-para">
          Our Crew Resource Management (CRM) and Line-Oriented Flight Training
          (LOFT) programs are designed to enhance teamwork, communication, and
          decision-making skills in the cockpit. These programs are essential
          for developing the non-technical skills necessary for safe and
          efficient flight operations.
        </p>
        <ul className="sim-program-ul">
          <li className="sim-program-li">Course Duration: 2-4 days.</li>
          <li className="sim-program-li">
            Eligibility: Open to pilots holding an A320/B737 type rating.
          </li>
          <li className="sim-program-li">Course Structure:</li>
          <li className="sim-program-li">
            CRM Training: In-depth workshops and simulator exercises focusing on
            human factors, communication, and team dynamics.
          </li>
          <li className="sim-program-li">
            LOFT Sessions: Realistic flight scenarios conducted in a full motion
            simulator to practice and reinforce CRM concepts in a real-world
            context.
          </li>
        </ul>
        {/*  */}
        <p className="sim-program-head">4. Jet Transition Program</p>
        <p className="sim-para">
          Our Jet Transition Program is ideal for pilots moving from general
          aviation or turboprop aircraft to jet operations. This course provides
          the foundational skills necessary to transition smoothly to jet
          aircraft, focusing on the specific demands of jet performance and
          operation.
        </p>
        <ul className="sim-program-ul">
          <li className="sim-program-li">Course Duration: 1-2 weeks.</li>
          <li className="sim-program-li">
            Eligibility: CPL/ATPL holders or pilots with turboprop experience.
          </li>
          <li className="sim-program-li">Course Structure:</li>
          <li className="sim-program-li">
            Ground School: Introduction to jet aerodynamics, high-speed flight,
            jet systems, and operating procedures.
          </li>
          <li className="sim-program-li">
            Simulator Training: Hands-on experience in a full motion simulator,
            covering all phases of flight, including takeoff, cruise, descent,
            and landing.
          </li>
          <li className="sim-program-li">
            Final Assessment: A simulator check to assess readiness for jet
            operations.
          </li>
        </ul>
        {/*  */}
        <p className="sim-subhead">
          Pre- and Post-Training Program in Trivandrum, Kerala
        </p>
        <p className="sim-para">
          In addition to our full motion simulator training, we also offer a
          Pre- and Post-Training Program using a fixed-base simulator for B737
          and A320 aircraft in Trivandrum, Kerala, India. This specialized
          training is ideal for:
        </p>
        <ul className="sim-program-ul">
          <li className="sim-program-li">
            Pilots Preparing for Simulator Rating: Get a head start with focused
            training before your official simulator rating sessions.
          </li>
          <li className="sim-program-li">
            Candidates Preparing for Type Interviews: Sharpen your skills and
            increase your confidence before attending type interviews.
          </li>
          <li className="sim-program-li">
            Pilots Refreshing Their Rating: Stay current and proficient by
            refreshing your skills and knowledge.
          </li>
          <li className="sim-program-li">
            Aviation Enthusiasts and Professionals: Experience the thrill of
            simulator flying or receive training in emergency procedures and
            other critical areas.
          </li>
          <li className="sim-program-li">
            Duration: The duration of this program varies depending on the
            course you choose and your individual needs.
          </li>
        </ul>
        <p className="sim-para">
          For more details and to discuss the course that’s right for you,
          contact us today.
        </p>
        <div className="fixed-sim-img-wrap">
          <img
            src={require("../../data/images/simulator/fixed-sim-1.jpeg")}
            alt=""
            className="fixed-sim-img"
          />
          <img
            src={require("../../data/images/simulator/fixed-sim-2.jpeg")}
            alt=""
            className="fixed-sim-img"
          />
          <img
            src={require("../../data/images/simulator/fixed-sim-3.jpeg")}
            alt=""
            className="fixed-sim-img"
          />
          <img
            src={require("../../data/images/simulator/fixed-sim-4.jpeg")}
            alt=""
            className="fixed-sim-img"
          />
          <img
            src={require("../../data/images/simulator/fixed-sim-5.jpeg")}
            alt=""
            className="fixed-sim-img"
          />
          {/* <img
            src={require("../../data/images/simulator/fixed-sim-6.jpeg")}
            alt=""
            className="fixed-sim-img"
          /> */}
          <img
            src={require("../../data/images/simulator/fixed-sim-7.jpeg")}
            alt=""
            className="fixed-sim-img"
          />
        </div>
        {/*  */}
        <p className="sim-subhead">Why Choose Akhurath Zenith Solutions LLC?</p>

        <ul className="sim-program-ul">
          <li className="sim-program-li">
            DGCA Approved Training Programs: Our training programs are fully
            approved by the Indian DGCA, ensuring compliance with all regulatory
            standards.
          </li>
          <li className="sim-program-li">
            State-of-the-Art Facilities: We partner with leading simulation
            centers equipped with the latest full motion simulators for the A320
            and B737 aircraft.
          </li>
          <li className="sim-program-li">
            Experienced Instructors: Our instructors are seasoned aviation
            professionals with extensive experience in both airline operations
            and pilot training.
          </li>
          <li className="sim-program-li">
            Customized Training Solutions: We tailor our programs to meet the
            specific needs of each pilot, ensuring a personalized training
            experience.
          </li>
          <li className="sim-program-li">
            Global Reach: Based in Tbilisi, Georgia, we cater to pilots from
            around the world, providing an international training environment.
          </li>
          <li className="sim-program-li">
            Accommodation and Transport Services: We understand the importance
            of comfort during your training. That’s why we provide complimentary
            accommodation in a comfortable setting, along with convenient pickup
            and drop-off services to and from the training facility.
          </li>
          <li className="sim-program-li">
            Competitive Industry Rates: We offer highly competitive rates for
            our training programs, providing excellent value without
            compromising on the quality of your training experience.
          </li>
        </ul>
        <p className="sim-para">
          Ready to take the next step in your aviation career? Contact Akhurath
          Zenith Solutions LLC today for more information on our A320/B737 Full
          Motion Simulator Training packages, our pre- and post-training program
          in Trivandrum, Kerala, including accommodation, transport services,
          and competitive pricing.
        </p>
        <p className="sim-para">
          Our team is here to assist you with any inquiries and to help you
          choose the right training program to meet your career goals.
        </p>
      </div>
    </div>
  );
};

export default Simulator;
