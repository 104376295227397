import React from "react";
import "./healthcare.scss";

const Healthcare = () => {
  return (
    <div className="healthcare-container container">
      <p className="health-head">Healthcare</p>
      <div className="health-wrapper">
        <p className="health-description">
          Welcome to Akhurath, where expertise meets excellence in project
          management services tailored specifically for the medical field. With
          our in-house team and global network of collaborations, we specialize
          in overseeing projects related to the establishment of medical
          facilities, research and development initiatives, and more.
        </p>
        <p className="health-description">
          From conceptualization to execution, we ensure seamless project
          management, leveraging our extensive experience and industry knowledge
          to deliver outstanding results. Whether you're setting up a new
          medical facility, conducting groundbreaking research, or implementing
          innovative healthcare solutions, Akhurath is your trusted partner
          every step of the way.
        </p>
        <p className="health-description">
          Partner with us to bring your medical projects to life with precision
          and efficiency,Let's make a difference in healthcare together.
        </p>
      </div>
    </div>
  );
};

export default Healthcare;
