import React from "react";
import "./partners.scss";
import Partner1 from "../../data/images/partner-img/Logo.png";
import Partner2 from "../../data/images/partner-img/partner_1.webp";
import Partner3 from "../../data/images/partner-img/partner_2.webp";
import Partner4 from "../../data/images/partner-img/partner_3.webp";
import Partner5 from "../../data/images/partner-img/partner_4.webp";
import Partner6 from "../../data/images/partner-img/realm-alpha.png";
import Partner7 from "../../data/images/partner-img/baa.png";
import Partner8 from "../../data/images/partner-img/partner_6.jpeg";

const Partners = () => {
  return (
    <div className="partner-container container">
      <p className="partner-head">Our Partners</p>
      <div className="partner-img-wrap">
        <img src={Partner1} alt="" className="partner-img" />
        <img src={Partner5} alt="" className="partner-img-wide" />
        <img src={Partner4} alt="" className="partner-img-wide" />
        <img src={Partner2} alt="" className="partner-img" />
        <img src={Partner3} alt="" className="partner-img" />
        <img src={Partner6} alt="" className="partner-img" />
        <img src={Partner7} alt="" className="partner-img-wide" />
        <img src={Partner8} alt="" className="partner-img" />
      </div>
    </div>
  );
};

export default Partners;
