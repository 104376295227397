import React, { useEffect, useState } from "react";
import "./contact.scss";
import { API } from "../../App";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  faArrowRight,
  faEnvelope,
  faLocationDot,
  faPhoneVolume,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const [sendBtnText, setSendBtnText] = useState("Send Message");
  const [sendBtnClass, setSendBtnClass] = useState("contact-submit");
  // const [sendBtnClass, setSendBtnClass] = useState("contact-submit-disabled");

  const [mounted, setMounted] = useState(false);

  // ///// Scroll to top
  if (!mounted) {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }

  useEffect(() => {
    setMounted(true);
  }, []);

  function sendMessage() {
    // document.getElementById("btnPlaceOrder").disabled = true;
    document.getElementById("sendBtn")?.setAttribute("disabled", "disabled");
    setSendBtnClass("contact-submit-disabled");
    setSendBtnText("Sending...");
    const body = {
      name: name,
      email: email,
      phone: phone,
      message: message,
    };
    API.post("/api/email/contact-us", body)
      .then((response) => {
        // response.data.forEach((element: any) => {
        //   console.log(element.msg);
        // });
        if ((response.status = 200)) {
          toast.success("Message send successfully");
          setName("");
          setEmail("");
          setPhone("");
          setMessage("");
        } else {
          toast.error("Somethin went wrong");
        }
        document.getElementById("sendBtn")?.removeAttribute("disabled");
        setSendBtnClass("contact-submit");
        setSendBtnText("Send Message");
      })
      .catch((error) => {
        const errArray = [];
        if (error.response) {
          error.response.data.forEach((element: any) => {
            console.log(element.msg);
            // errArray.push(element.msg);
            toast.error(element.msg);
          });
        } else {
          toast.error(error.message);
          // alert(error.message);
        }
        document.getElementById("sendBtn")?.removeAttribute("disabled");
        setSendBtnClass("contact-submit");
        setSendBtnText("Send Message");

        // console.log(error.response);
      });
  }

  return (
    <div className="contact-container">
      <div className="contact-wraper">
        <div className="drop-a-line">
          <div className="drop-heading-wrapper">
            <p className="drop-heading">Drop Us a Line</p>
          </div>
          <div className="contact-form">
            <input
              className="contact-input"
              onChange={(e) => setName(e.target.value)}
              type="text"
              id="name"
              name="name"
              placeholder="Name"
              value={name}
            />
            <input
              className="contact-input"
              onChange={(e) => setPhone(e.target.value)}
              type="text"
              id="phone"
              name="phone"
              placeholder="Mobile number"
              value={phone}
            />
            <input
              className="contact-input"
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              id="email"
              name="email"
              placeholder="E-mail"
              value={email}
            />
            <textarea
              onChange={(e) => setMessage(e.target.value)}
              name="message"
              id="message"
              placeholder="Message"
              className="contact-textarea"
              value={message}
            ></textarea>
            <button id="sendBtn" onClick={sendMessage} className={sendBtnClass}>
              {sendBtnText}
            </button>
          </div>
        </div>
        {/*  */}
        <div className="contact-options">
          <p className="options-heading">Get in touch</p>
          <div className="options-wrapper">
            <div className="option">
              <div className="option-icon-wrapper">
                <FontAwesomeIcon className="option-icon" icon={faPhoneVolume} />
              </div>
              <div className="option-content">
                <div className="option-content-title">Phone</div>
                <a
                  href="tel:+995571993339"
                  className="option-content-text option-content-link"
                >
                  +995-571993339
                </a>
                <a
                  href="tel:+919567299101"
                  className="option-content-text option-content-link"
                >
                  +91-9567299101
                </a>
              </div>
            </div>
            <div className="option">
              <div className="option-icon-wrapper">
                <FontAwesomeIcon className="option-icon" icon={faEnvelope} />
              </div>
              <div className="option-content">
                <div className="option-content-title">Email</div>
                <a
                  href="mailto:business@akhurath.co"
                  className="option-content-text option-content-link"
                >
                  business@akhurath.co
                </a>
              </div>
            </div>
            <div className="option">
              <div className="option-icon-wrapper">
                <FontAwesomeIcon className="option-icon" icon={faLocationDot} />
              </div>
              <div className="option-content">
                <div className="option-content-title">Address</div>
                <p className="option-content-text">AKHURATH ZENITH SOLUTIONS</p>
                <p className="option-content-text">
                  FLOOR 2 N4A, POLICE DEAD END N5, SAMGORI
                  DISTRICT,TBILISI,GEORGIA
                </p>
                <p className="option-content-text">
                  *IDENTIFICATION NUMBER : 406457524*
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Contact;
