import React, { useEffect, useState } from "react";
import "./research.scss";
import Img1 from "../../data/images/aviation/r&d/img-1.jpeg";

const Research = () => {
  const [mounted, setMounted] = useState(false);
  if (!mounted) {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }

  useEffect(() => {
    setMounted(true);
  }, []);
  return (
    <div className="research-container">
      <p className="research-heading">R&D</p>
      <div className="reserch-wrapper container">
        <div className="research-content">
          <p className="research-subhead">Lorem Ipsum</p>
          <p className="research-para">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum
            consectetur ipsum mollitia voluptatem voluptatibus aliquam
            reprehenderit incidunt amet esse dolor totam facilis optio itaque,
            cum id qui voluptas voluptates excepturi ex sit. Itaque sequi
            repellendus suscipit quaerat est sed porro voluptate commodi beatae,
            praesentium dolorum voluptatibus, nesciunt sapiente odit aut?
          </p>
          <p className="research-para">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum
            consectetur ipsum mollitia voluptatem voluptatibus aliquam
            reprehenderit incidunt amet esse dolor totam facilis optio itaque,
            cum id qui
          </p>
          <p className="research-para">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum
            consectetur ipsum mollitia voluptatem voluptatibus
          </p>
        </div>
        <div className="research-galary">
          <img src={Img1} alt="" className="reserch-img" />
          <img src={Img1} alt="" className="reserch-img" />
          <img src={Img1} alt="" className="reserch-img" />
        </div>
      </div>
    </div>
  );
};

export default Research;
