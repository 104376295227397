export const SalesList = [
  {
    aircraft_name: "Boeing 777-200LR",
    Seats: "317 persons (2-class)",
    range: "15,843 km",
    engine: "GE90-115BL",
    img: require("../images/aviation/sales/B-777.jpeg"),
  },
  {
    aircraft_name: "Boeing 737",
    Seats: "149 persons (2-class)",
    range: "7,040 km",
    engine: "CFM-56",
    img: require("../images/aviation/sales/b737.jpg"),
  },
  {
    aircraft_name: "Boeing 767",
    Seats: " 52,480 kg",
    range: "6028.26 km",
    engine: "",
    img: require("../images/aviation/sales/b767.jpeg"),
  },
  {
    aircraft_name: "Airbus A320",
    Seats: "180 persons",
    range: "6300 km",
    engine: "",
    img: require("../images/aviation/sales/a320.jpg"),
  },
  {
    aircraft_name: "Airbus A220-300",
    Seats: "160 persons",
    range: "6,297 km",
    engine: "",
    img: require("../images/aviation/sales/A220-300.jpg"),
  },
  {
    aircraft_name: "Airbus A330-900",
    Seats: "300 persons",
    range: "13,334 km",
    engine: "",
    img: require("../images/aviation/sales/a330.jpg"),
  },
  {
    aircraft_name: "Embraer E 190",
    Seats: "100 persons",
    range: "4,537 km",
    engine: "",
    img: require("../images/aviation/sales/e190.jpg"),
  },
  {
    aircraft_name: "ATR 72-600",
    Seats: "78 persons",
    range: "1,370km",
    engine: "PW127XT",
    img: require("../images/aviation/sales/atr72.jpg"),
  },
  {
    aircraft_name: "ATR 42-600",
    Seats: "50 persons",
    range: "1,345km",
    engine: "PW127XT",
    img: require("../images/aviation/sales/atr42.png"),
  },
];
