import React, { useEffect, useState } from "react";
import "./sales.scss";
// import SalesList from "../../data/assets/sales-list.json";
import TestIm from "../../data/images/aviation/sales/B-777.jpeg";
import { SalesList } from "../../data/assets/sales-list";
import { Link } from "react-router-dom";

const Sales = () => {
  const [mounted, setMounted] = useState(false);

  if (!mounted) {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }

  useEffect(() => {
    setMounted(true);
  }, []);
  return (
    <div className="sales-container">
      <div className="sales-wrapper container">
        <p className="sales-heading">Aircraft Sales and Leasing</p>
        <div className="sales-items">
          {SalesList.map(function (item, i) {
            return (
              <div className="sales-card">
                <div className="sales-img-wrap">
                  <img src={item.img} alt="" className="sales-img" />
                  {/* <img src={TestIm} alt="" className="sales-img" /> */}
                </div>
                <div className="sales-item-details">
                  <p className="sales-item-name">{item.aircraft_name}</p>
                  <p className="sales-item-seats sales-item-text">
                    Capacity : {item.Seats}
                  </p>
                  <p className="sales-item-range sales-item-text">
                    Range : {item.range}
                  </p>
                  <p className="sales-item-engine sales-item-text">
                    Engine : {item.engine}
                  </p>
                </div>
                <Link
                  to={"/aviation/sales_lease/inquire/" + item.aircraft_name}
                >
                  <button
                    id="inquire-btn"
                    onClick={() => {
                      return "";
                    }}
                    className="inquire-submit"
                  >
                    Inquire Now
                  </button>
                </Link>
              </div>
            );
          })}
          <div className="sales-vard"></div>
        </div>
      </div>
    </div>
  );
};

export default Sales;
