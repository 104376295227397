import React from "react";
import "./aboutus.scss";

const AboutUs = () => {
  return (
    <div className="aboutus-container container">
      <div className="aboutus-head">About Us</div>
      <div className="aboutus-content">
        <p className="aboutus-descriptionn">
          {" "}
          Welcome to Akhurath Zenith Solutions, your premier global project
          management company headquartered in Tbilisi, Georgia. As the youngest
          addition to our esteemed collaboration, Akhurath brings a fresh
          perspective and dynamic energy to the table. Despite our youth, our
          in-house team and expert consultants boast an average experience of
          over 50 plus years across diverse fields.
        </p>
        <p className="aboutus-descriptionn">
          {" "}
          At Akhurath Zenith Solutions, we pride ourselves on our ability to
          navigate complex projects with finesse and precision. Our unique blend
          of seasoned expertise and innovative thinking ensures that our clients
          receive the best of both worlds – the wisdom of seasoned professionals
          combined with the agility of fresh minds.{" "}
        </p>
        <p className="aboutus-descriptionn">
          {" "}
          With a global reach and a commitment to excellence, Akhurath Zenith
          Solutions is your trusted partner for success. Join us as we redefine
          project management for the future.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
