import React from "react";
import "./services.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const Services = () => {
  return (
    <div className="services-container">
      <div className="services-wrapper container">
        <p className="services-heading">Our Services</p>
        <div className="serv-card-container">
          <div className="serv-card-wrap">
            <div className="serv-card-3d"></div>
            <div className="serv-card">
              <div className="serv-head-wrap">
                <p className="serv-heading">Aviation</p>
              </div>
              <p className="serv-caption">
                <p className="aviation-country">Vietnam, India, Georgia</p>
                At Akhurath, we blend our in-house expertise with worldwide
                collaborations to serve a diverse range of aviation profiles.  
              </p>
              <Link to="/aviation">
                <button className="serv-button">
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </Link>
            </div>
          </div>
          {/*  */}
          <div className="serv-card-wrap">
            <div className="serv-card-3d"></div>
            <div className="serv-card">
              <div className="serv-head-wrap">
                <p className="serv-heading">Investment</p>
              </div>
              <p className="serv-caption">
                Our investor pool comprises both esteemed companies and
                high-net-worth individuals who share our vision for growth and
                innovation
              </p>
              <Link to="/investment">
                <button className="serv-button">
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </Link>
            </div>
          </div>
          {/*  */}
          <div className="serv-card-wrap">
            <div className="serv-card-3d"></div>
            <div className="serv-card">
              <div className="serv-head-wrap">
                <p className="serv-heading">HealthCare</p>
              </div>
              <p className="serv-caption">
                Partner with us to bring your medical projects to life with
                precision and efficiency,Let's make a difference in healthcare
                together{" "}
              </p>
              <Link to="/healthcare">
                <button className="serv-button">
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
