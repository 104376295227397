import React from "react";
import "./brand.scss";
import Logo from "../../data/images/akhurath-logo.png";

const Brand = () => {
  return (
    <div className="brand-container">
      <div className="brand-background">
        <img src={Logo} alt="" className="brand-logo" />
      </div>
    </div>
  );
};

export default Brand;
