import React from "react";
import "./App.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/home/home";
import Navbar from "./features/navbar/navbar";
import Footer from "./features/footer/footer";
import Aviation from "./components/aviation/aviation";
import Healthcare from "./components/healthcare/healthcare";
import AboutUs from "./components/aboutus/aboutus";
import ReactGA from "react-ga4";
import Investments from "./components/investments/investments";
import Contact from "./components/contact/contact";
import axios from "axios";
import Research from "./components/research/research";
import Sales from "./components/sales/sales";
import Inquire from "./components/inquire/inquire";
import Simulator from "./components/simulator/simulator";

export const API = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

function App() {
  ReactGA.initialize("G-2CHML89Y58");
  console.log(process.env.REACT_APP_BASE_URL); ///////

  return (
    <BrowserRouter>
      <div className="App">
        <header className="App-header">
          <Navbar />
        </header>
        <div className="router-container">
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/aviation" element={<Aviation />}></Route>
            <Route path="/aviation/sales_lease" element={<Sales />}></Route>
            <Route
              path="/aviation/sales_lease/inquire/:aircraft_name"
              element={<Inquire />}
            ></Route>
            <Route path="/aviation/research" element={<Research />}></Route>
            <Route path="/aviation/simulators" element={<Simulator />}></Route>
            <Route path="/investment" element={<Investments />}></Route>
            <Route path="/healthcare" element={<Healthcare />}></Route>
            <Route path="/aboutus" element={<AboutUs />}></Route>
            <Route path="/contact-us" element={<Contact />}></Route>
          </Routes>
        </div>
        <footer className="App-footer">
          <Footer />
        </footer>
      </div>
    </BrowserRouter>
  );
}

export default App;
