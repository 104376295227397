import React from "react";
import "./investments.scss";
import FlowImg from "../../data/images/investment/flowchart.jpeg";

const Investments = () => {
  return (
    <div className="investment-container container">
      <p className="investment-heading">Investment</p>
      <div className="investment-wrapper">
        <div className="investmeent-description">
          <p className="investmeent-desc-text">
            We would like to provide you with an overview of our investor
            engagement process at Akhurath Zenith Solutions LLC. Our investor
            pool comprises both esteemed companies and high-net-worth
            individuals who share our vision for growth and innovation.
          </p>
          <p className="investmeent-desc-text">
            Below, I’ve outlined the key steps we follow when collaborating with
            potential investors:
          </p>
        </div>
        <div className="investment-content">
          <ul className="investment-mainlist">
            <li className="investment-mainlist-item">
              <p className="investment-list-title">
                Initial Contact and Discussion
              </p>
              <ul className="investment-sublist">
                <li className="investment-sublist-item">
                  When a project team expresses interest in seeking investment,
                  they reach out to us with their specific requirements and the
                  value they bring to the table.
                </li>
                <li className="investment-sublist-item">
                  We engage in open dialogue to understand their goals,
                  aspirations, and unique selling points.
                </li>
              </ul>
            </li>
          </ul>
          <ul className="investment-mainlist">
            <li className="investment-mainlist-item">
              <p className="investment-list-title">Documentation Gathering</p>
              <ul className="investment-sublist">
                <li className="investment-sublist-item">
                  We collect essential documents, including a mandate,
                  Non-Disclosure Agreement (NDA), and any other relevant
                  paperwork from the project team.
                </li>
                <li className="investment-sublist-item">
                  These documents serve as the foundation for transparent and
                  efficient communication.
                </li>
              </ul>
            </li>
          </ul>

          <ul className="investment-mainlist">
            <li className="investment-mainlist-item">
              <p className="investment-list-title">Investor Review :</p>
              <ul className="investment-sublist">
                <li className="investment-sublist-item">
                  Our experienced investor team evaluates the project’s
                  potential based on the provided information.
                </li>
                <li className="investment-sublist-item">
                  Depending on the investor’s preference, we either facilitate
                  direct communication between the investor and the project team
                  or act as intermediaries.
                </li>
              </ul>
            </li>
          </ul>

          <ul className="investment-mainlist">
            <li className="investment-mainlist-item">
              <p className="investment-list-title">
                Project Feasibility Study :
              </p>
              <ul className="investment-sublist">
                <li className="investment-sublist-item">
                  We conduct a thorough feasibility study, analyzing various
                  aspects of the project.
                </li>
                <li className="investment-sublist-item">
                  Our findings are then shared with the investors, allowing them
                  to make informed decisions.
                </li>
              </ul>
            </li>
          </ul>

          <ul className="investment-mainlist">
            <li className="investment-mainlist-item">
              <p className="investment-list-title">Investor Decision</p>
              <ul className="investment-sublist">
                <li className="investment-sublist-item">
                  Armed with the feasibility study report and the relevant
                  documents, the investor assesses whether the project aligns
                  with their investment strategy.
                </li>
                <li className="investment-sublist-item">
                  This step is crucial in determining the next course of action.
                </li>
              </ul>
            </li>
          </ul>
          <ul className="investment-mainlist">
            <li className="investment-mainlist-item">
              <p className="investment-list-title">
                Formal Agreements and Commitment{" "}
              </p>
              <ul className="investment-sublist">
                <li className="investment-sublist-item">
                  Once both parties are aligned, we proceed to sign the NDA,
                  establish a Letter of Intent (LOI), and define the mandate.
                </li>
                <li className="investment-sublist-item">
                  These agreements solidify commitment and ensure a serious
                  intent to move forward.
                </li>
              </ul>
            </li>
          </ul>
          <img src={FlowImg} alt="" className="investment-flowchart" />
        </div>
      </div>
    </div>
  );
};

export default Investments;
