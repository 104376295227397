import React from "react";
import "./home.scss";
import LandingCarousel from "../../features/landing-carousel/landing-carousel";
import Services from "../../features/services/services";
import Partners from "../../features/partners/partners";

const Home = () => {
  return (
    <div className="home-container">
      <LandingCarousel />
      <Services />
      <Partners />
    </div>
  );
};

export default Home;
